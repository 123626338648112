<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        Import History
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Imports</a></li>
          <li class="breadcrumb-item active" aria-current="page">History</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-3">
        <form action="#">
          <div class="form-group d-lg-flex">
            <input type="number" class="form-control" @change="paginateChange" placeholder="perPage" v-model="paginate">
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
<!--            <h4 class="card-title">Imports</h4>-->
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <h4 class="card-title">Imports</h4>
              <download-excel
                class="btn btn-success"
                :data="history"
                :fields="json_fields"
                worksheet="My Worksheet"
                name="plans.xlsx">
                Download Imports
              </download-excel>
            </div>
            <b-table :items="history" id="table-list" responsive :busy="isBusy" :per-page="perPage" :current-page="currentPage" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:cell(created_at)="data">
                <span>{{ formatTime(data.item.created_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(updated_at)="data">
                <span>{{ formatTime(data.item.updated_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(status)="data">
                <b-badge pill :variant="successClass(data.item.status)">{{ successText(data.item.status) }}</b-badge>
              </template>
              <template v-slot:cell(bad_trans_path)="data">
                <span v-if="data.item.bad_trans_path != null">
                  <a :href="data.item.bad_trans_path" target="_blank">Download</a>
                </span>
                <span v-if="!data.item.bad_trans_path">
                  N/A
                </span>
              </template>
              <template v-slot:cell(remarks)="data">
                <span>{{ (data.item.remarks == null) ? "N/A" : data.item.remarks }}</span>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
      </div>
    <div>
    </div>
  </section>
</template>
<script>
import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
//import _ from 'lodash'

import helper from '../../util/helper.js'
import _ from "lodash";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  data: function() {
    return {
      searchInput: "",
      currentPage: 1,
      perPage: 0,
      paginate: 10,
      isBusy: true,
      sortBy: 'name',
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { key: 'id', sortable: true },
        { key: 'title', sortable: true },
        { key: 'file_name', sortable: true },
        { key: 'progress', sortable: true },
        { key: 'status', sortable: true },
        {key: 'remarks', sortable: false},
        {key: 'bad_trans_path', sortable: false},
        { key: 'created_at', sortable: true },
        { key: 'updated_at', sortable: true }
      ],
      json_fields: {
        'ID': 'id',
        "Title": "title",
        'Remarks': 'remarks',
        'File Name': 'file_name',
        'Created': 'created_at',
        'status': 'status',
      }
    }
  },
  computed: {
    rows() {
      return this.$store.state.import.meta.total
    },
    history() {
      return this.$store.state.import.imports
    }
  },
  watch: {
    currentPage() {
      if(this.currentPage != this.$store.state.import.meta.current_page)
        this.importHistory(this.currentPage)
    }
  },
  methods: {
    formatTime(time, format) {
      return helper.formatTime(time, format)
    },
    successClass(val) {
      if(val == "1") return "success"
      return "danger"
    },
    successText(val) {
      if(val == "1") return "Successful"
      return "Failed"
    },
    importHistory(current_page = 1) {
      this.isBusy = true
      const payload = {
        meta: {
          page: current_page,
          perPage: this.paginationPageSize
        },
      }
      this.$store.dispatch("import/importHistory", payload)
      .then(() => {
        this.isBusy = false
      })
      .catch(() => {
        this.isBusy = false
      })
    },
    paginateChange: _.debounce(function() {
      if (this.paginate > 0 && this.paginate != "")
        this.importHistory()
    }, 500),
  },
  created() {
    this.importHistory()
 },
}
</script>
